import { type FC } from 'react';
import logoPrimary from 'assets/images/common/logo_primary.png';

const PrimaryLogo: FC = () => (
  <img
    alt="Growth Support"
    className="growth-support logo-1 p-headerLogo__image"
    src={logoPrimary}
    width="250"
    height="35"
  />
);

export default PrimaryLogo;
